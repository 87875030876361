$(document).ready(function() {
	$(document).foundation();
});


$('input[name=_token]').val($('meta[name=csrf-token]').attr("content"));



$('.confirm').on('click', function(e){
	var $form = $(this).closest('form');
	e.preventDefault();

	$('#deleteModal').modal({ backdrop: 'static', keyboard: false })
		.one('click', '#delete', function (e) {
			$form.trigger('submit');
		});
});



tinymce.init({
	selector: 'textarea.rte',
	browser_spellcheck: true,
	spellchecker_language: 'English=en',
	body_class: 'content',
	height: 500,
	theme: 'modern',
	plugins: [
		'advlist autolink lists link image charmap print preview hr anchor pagebreak spellchecker',
		'searchreplace wordcount visualblocks visualchars code fullscreen',
		'insertdatetime media nonbreaking save table contextmenu directionality',
		'emoticons template paste textcolor colorpicker textpattern imagetools'
	],
	toolbar1: 'insertfile undo redo | styleselect fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
	toolbar2: 'print preview media | forecolor backcolor emoticons',
	image_advtab: true,
	content_css : '/css/app.css'
});



var map;

var MY_MAPTYPE_ID = 'Greyscale';

function initialize() {

	var graystyle = [
		{
			featureType: "all",
			elementType: "all",
			stylers: [ { saturation: -100 }, { visibility: "simplified" }, { "invert_lightness": true }, { "hue": "#242126" } ]
		}
	];

	var mapOptions = {
		zoom: 13,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
		},
		mapTypeId: MY_MAPTYPE_ID,
		scrollwheel: false
	};

	map = new google.maps.Map(document.getElementById("map_canvas"),
		mapOptions);

	var styledMapOptions = {
		name: "Greyscale"
	};

	var grayMapType = new google.maps.StyledMapType(graystyle, styledMapOptions);

	map.mapTypes.set(MY_MAPTYPE_ID, grayMapType);
}

geocoder = new google.maps.Geocoder();
var pinColor = "0099cc";
var pinImage = new google.maps.MarkerImage("//chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
	new google.maps.Size(21, 34),
	new google.maps.Point(0,0),
	new google.maps.Point(10, 34));

function codeAddress() {
	//In this case it gets the address from an element on the page, but obviously you  could just pass it to the method instead
	var address = '17 Market Street, Box Hill Vic 3128, Australia';

	geocoder.geocode( { 'address': address}, function(results, status) {
		if (status == google.maps.GeocoderStatus.OK) {
			//In this case it creates a marker, but you can get the lat and lng from the location.LatLng
			map.setCenter(results[0].geometry.location);
			var marker = new google.maps.Marker({
				map: map,
				position: results[0].geometry.location,
				icon: pinImage
			});
		} else {
			alert("Geocode was not successful for the following reason: " + status);
		}
	});
}
initialize();
codeAddress();